import { DocumentError, QuestionAnswerPair, SignatureStore } from "@ambii-com/core";
import { FormQuestion } from "@ambii-com/core";
import { FormPage } from "@ambii-com/core";
import { instanceToPlain, plainToInstance, Transform } from "class-transformer";
import { FormQuestionComponents, PatientInfoTags, Sex } from "@ambii-com/core";
import {
  PatientInfo,
  Answer,
  AnswerContentBase,
  AddressContent,
  DateContent,
  AppointmentDatetimeContent,
  NameContent,
  NameKanaContent,
  StringAnswerContent,
  ArrayAnswerContent,
  FileAnswerStore,
  FileAnswerContent,
  SchemaStore,
  EmailAddressContent,
  AppointmentSchedule,
  Appointment
} from "@ambii-com/core";
import dayjs from "dayjs";
import { AppointmentForm } from "./AppointmentForm";
import "reflect-metadata";

export interface AppointmentData {
  schedule: AppointmentSchedule,
  appointment: Appointment
}

export class FormSession {
  static pageToPairsMapFromPlain(
    value: object
  ): Map<PageId, Array<QuestionAnswerPair>> {
    if (!value) return undefined;
    return new Map(
      Object.entries(value).map(([pageId, plainObjs]: [PageId, any[]]) => {
        return [
          pageId,
          plainObjs.map((plainObj: { question: Object; answer: Object }) => {
            return plainToInstance(QuestionAnswerPair, plainObj);
          }),
        ];
      })
    );
  }
  @Transform(({ value }) => FormSession.pageToPairsMapFromPlain(value), {
    toClassOnly: true,
  })
  pageToPairsMap: Map<PageId, Array<QuestionAnswerPair>>;
  appointmentData?: AppointmentData
  constructor(pageToPairsMap: Map<PageId, Array<QuestionAnswerPair>>, appointmentData?: AppointmentData) {
    this.pageToPairsMap = pageToPairsMap;
    this.appointmentData = appointmentData
  }
  static initializeFormSessionFromPageIds(pageIds: PageId[]): FormSession {
    const formSession: Map<PageId, Array<QuestionAnswerPair>> = new Map();
    pageIds.forEach((pageId) => {
      formSession.set(pageId, []);
    });
    return new FormSession(formSession);
  }
  static createFromPlain(plain: Object): FormSession {
    if (plain.hasOwnProperty("createdAt")) delete plain["createdAt"];
    return plainToInstance(FormSession, plain);
  }
  get retrieveAllPairs(): QuestionAnswerPair[] {
    return Array.from(this.pageToPairsMap.values()).flat();
  }
  stripUnbackupableContents(): FormSession {
    return new FormSession(new Map(Array.from(this.pageToPairsMap.entries())
      .map(([pageId, pairs]) => {
        return [pageId, pairs.map(pair => pair.stripUnbackupableContent())]
      })), this.appointmentData)
  }
  retrievePairsBeforePage(currentPageId: PageId): QuestionAnswerPair[] {
    const pairs: QuestionAnswerPair[][] = [];
    if (!this.pageToPairsMap.has(currentPageId)) {
      throw new Error(
        `Cannot find supplied pageId:${currentPageId} in FormSession`
      );
    }
    for (const [pageId, questionAnswerPair] of this.pageToPairsMap) {
      if (pageId !== currentPageId) {
        pairs.push(questionAnswerPair);
      } else if (pageId === currentPageId) {
        break;
      }
    }
    return pairs.flat();
  }
  buildQuestionAnswerPairs(
    pageId: PageId,
    documents: (FormQuestion | DocumentError)[],
    appointment?: AppointmentData
  ) {
    const pairs: QuestionAnswerPair[] = [];
    documents.forEach((document) => {
      // filter out DocumentErrors for now
      if(document instanceof FormQuestion){
        const pair = new QuestionAnswerPair(document, document.defaultAnswer);
        if(appointment) this.fillPatientInfoFromAppointment(pair, appointment)
        pairs.push(pair)
      }
    });
    this.pageToPairsMap.set(pageId, pairs);
  }
  fillPatientInfoFromAppointment(pair: QuestionAnswerPair, appointmentData: AppointmentData): QuestionAnswerPair{
    const { schedule, appointment } = appointmentData
    switch (pair.question.dashboardMetadata.patientInfoTag) {
      // case PatientInfoTags.NEW_PATIENT:
      //   break;
      case PatientInfoTags.NAME:
        if (appointment.firstName && appointment.lastName && pair.answer instanceof NameContent) {
          pair.answer.firstName = appointment.firstName
          pair.answer.familyName = appointment.lastName
        }
        break;
      case PatientInfoTags.NAME_PRONUNCIATION:
        if (appointment.firstNameKana && appointment.lastNameKana && pair.answer instanceof NameKanaContent) {
          pair.answer.firstNameKana = appointment.firstNameKana
          pair.answer.familyNameKana = appointment.lastNameKana
        }
        break;
      case PatientInfoTags.CARD_NUMBER:
        if (appointment.cardNumber && pair.answer instanceof StringAnswerContent) {
          pair.answer.value = appointment.cardNumber
        }
        break;
      case PatientInfoTags.DATE_OF_BIRTH:
        if (appointment.dob && pair.answer instanceof DateContent) {
          const date = dayjs(appointment.dob)
          pair.answer.year = date.year().toString()
          pair.answer.month = (date.month() + 1).toString()
          pair.answer.date = date.date().toString()
        }
        break;
      case PatientInfoTags.SEX:
        if (appointment.sex && pair.answer instanceof ArrayAnswerContent) {
          pair.answer.values = [appointment.sex]
        }
        break;
      case PatientInfoTags.CELL_PHONE:
        if (appointment.tel && pair.answer instanceof StringAnswerContent) {
          pair.answer.value = appointment.tel
        }
        break;
      // case PatientInfoTags.ADDRESS:
      //   break;
      case PatientInfoTags.EMAIL_ADDRESS:
        if (appointment.contactEmail && pair.answer instanceof EmailAddressContent) {
          pair.answer.emailAddress = appointment.contactEmail
        }
        break;
      case PatientInfoTags.APPOINTMENT_DATETIME:
        if (schedule && pair.answer instanceof AppointmentDatetimeContent) {
          const timeSlot = schedule.getTimeSlot(appointment.index)
          pair.answer.hasAppointment = true
          pair.answer.date = dayjs(timeSlot.startDate).format('YYYY-MM-DD')
          pair.answer.time = timeSlot.startTime
        }
        break;
    }
    return pair
  }
  updateQuestionAnswerPairs(pageId: PageId, pairs: QuestionAnswerPair[]) {
    if (!this.pageToPairsMap.has(pageId)) {
      throw new Error("PageId does not exist in FormSession");
    } else {
      this.pageToPairsMap.set(pageId, pairs);
    }
  }
  // Used when editing single answer in confirm page
  editQuestionAnswerPair(
    pageId: PageId,
    newPair: QuestionAnswerPair
  ): FormSession {
    const oldPairs = this.pageToPairsMap.get(pageId);
    if (oldPairs === undefined) {
      throw new Error("PageId does not exist in FormSession");
    }
    const oldPairIndex = oldPairs.findIndex(
      (oldPair) => oldPair.question.id === newPair.question.id
    );
    if (oldPairIndex === undefined) {
      throw new Error("QuestionAnswerPair not found in FormSession");
    }
    oldPairs.splice(oldPairIndex, 1, newPair.clone());
    return new FormSession(this.pageToPairsMap.set(pageId, oldPairs), this.appointmentData);
  }
  createAnswers(formPages: FormPage[], storageFolder: string): Map<string, Array<Answer>> {
    return new Map(
      Array.from(this.pageToPairsMap.entries()).map(
        ([pageId, QuestionAnswerPairs]) => {
          return [
            pageId,
            QuestionAnswerPairs.map((questionAnswerPair) => {
              if(questionAnswerPair.answer instanceof FileAnswerStore){
                questionAnswerPair = questionAnswerPair.convertStoreToContent(storageFolder)
              }
              else if (questionAnswerPair.answer instanceof SignatureStore || questionAnswerPair.answer instanceof SchemaStore) {
                questionAnswerPair = questionAnswerPair.convertStoreToContent(storageFolder)
              }
              return questionAnswerPair.createAnswer(
                this.pageToPairsMap,
                formPages
              );
            }),
          ];
        }
      )
    );
  }
  get extractPatientInfo(): PatientInfo {
    const patientInfo: PatientInfo = {};
    for (const pair of this.retrieveAllPairs) {
      switch (pair.question.dashboardMetadata.patientInfoTag) {
        case PatientInfoTags.NEW_PATIENT:
          patientInfo.newPatient = pair.mapToMedicalTerms
            ? pair.mapToMedicalTerms.toDisplayText
            : pair.answer.toDisplayText;
          break;
        case PatientInfoTags.NAME:
          patientInfo.familyName = (pair.answer as NameContent).familyName;
          patientInfo.firstName = (pair.answer as NameContent).firstName;
          break;
        case PatientInfoTags.NAME_PRONUNCIATION:
          patientInfo.familyNameKana = (
            pair.answer as NameKanaContent
          ).familyNameKana;
          patientInfo.firstNameKana = (
            pair.answer as NameKanaContent
          ).firstNameKana;
          break;
        case PatientInfoTags.CARD_NUMBER:
          patientInfo.cardNumber = pair.answer.toDisplayText;
          break;
        case PatientInfoTags.DATE_OF_BIRTH:
          patientInfo.dob = pair.answer.toDisplayText;
          break;
        case PatientInfoTags.SEX:
          patientInfo.sex = pair.mapToMedicalTerms
            ? pair.mapToMedicalTerms.toDisplayText
            : pair.answer.toDisplayText;
          break;
        case PatientInfoTags.HOME_PHONE:
          patientInfo.homePhone = pair.answer.toDisplayText;
          break;
        case PatientInfoTags.CELL_PHONE:
          patientInfo.cellPhone = pair.answer.toDisplayText;
          break;
        case PatientInfoTags.EMAIL_ADDRESS:
          patientInfo.emailAddress = pair.answer.toDisplayText;
          break;
        case PatientInfoTags.ADDRESS:
          patientInfo.address = (pair.answer as AddressContent).toDisplayText;
          break;
        case PatientInfoTags.APPOINTMENT_DATETIME:
          patientInfo.appointmentDatetime = (
            pair.answer as AppointmentDatetimeContent
          ).toDisplayText;
          break;
        case PatientInfoTags.EMAIL_ADDRESS:
          patientInfo.emailAddress = (pair.answer as EmailAddressContent).toDisplayText;
          break;
      }
    }
    return patientInfo;
  }
  get filesForStorage(): File[] {
    return this.retrieveAllPairs
      .map((pair) => pair.answer)
      .filter((answer: AnswerContentBase) => answer instanceof FileAnswerStore)
      .flatMap((answer: FileAnswerStore) => {
          return answer.allFiles
      })
  }
  get signaturePairs(): QuestionAnswerPair[] {
    return this.retrieveAllPairs
      .filter((pair: QuestionAnswerPair) => pair.answer instanceof SignatureStore)
  }
  get schemaPairs(): QuestionAnswerPair[] {
    return this.retrieveAllPairs
      .filter((pair: QuestionAnswerPair) => pair.answer instanceof SchemaStore)
  }
  convertToObject(): Object {
    return { createdAt: Date.now(), ...instanceToPlain(this) };
  }
}
