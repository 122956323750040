import { Context } from "@nuxt/types";
import { auth } from "~/setups/firebase";
import { onAuthStateChanged, User } from "firebase/auth";

export default function ({ store }: Context) {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user: User) => {
      if (user) {
        store.dispatch("auth/setUser", user);
      }
      // TODO
      resolve(null);
      // const is2FAAdded = user && user.multiFactor && user.multiFactor.enrolledFactors.length > 0
      // if(is2FAAdded) {
      //   resolve(null)
      // } else {
      //   // ...
      // }
    });
  });
}
