import { fsLocations } from "~/dao/FsLocations";
import { UserRole } from "@ambii-com/core";

// /app currently throws a 'existing appointments' error for admins due to how appointments are made in /admin
export default async function ({ store, redirect, route, app }) {
  if (store.getters["auth/isLoggedIn"]) {
    const isAdmin: boolean = await fsLocations
      .getAdminsRefByUserId(store.getters["auth/getUserId"])
      .get()
      .then((adminQuery) => {
        return adminQuery.empty
          ? false
          : adminQuery.docs[0].data().role === UserRole.CLINIC;
      });
    if (isAdmin) {
      alert(app.i18n.t("middleware.pleaseUsePatientAccount"));
      await store.dispatch("auth/logout");
      redirect({
        path: app.localePath("/appointment/login"),
        query: { from: route.path },
      });
    }
  }
}
