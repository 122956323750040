
export default {
  name: "OnProgress",
  props: {
    message: String,
  },
  computed: {
    isSending() {
      return this.message === this.$t("form.confirm.sendingProgress")
    }
  }
}
