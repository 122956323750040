export default {
  apiKey: "AIzaSyB6vCHLtysLWBfyy67hg5RmDATAHWRTxxI",
  authDomain: "clinics-frontend.firebaseapp.com",
  databaseURL: "https://clinics-frontend-default-rtdb.firebaseio.com",
  projectId: "clinics-frontend",
  storageBucket: "clinics-frontend.appspot.com",
  messagingSenderId: "880015089065",
  appId: "1:880015089065:web:a2fcb0ba981d63a25d38b8",
  measurementId: "G-RXCFDY6WNJ",
};
