import { AppointmentForm } from "~/classes/AppointmentForm";
import { GetterTree, ActionTree, MutationTree } from "vuex";

export const state = () => ({
  menuId: null as string,
  appointmentForm: null as AppointmentForm|null,
});

type AppointmentFormState = ReturnType<typeof state>;

export const getters: GetterTree<AppointmentFormState, any> = {};

export const mutations: MutationTree<AppointmentFormState> = {
  SET_APPOINTMENT_FORM(state, appointmentForm: AppointmentForm) {
    state.appointmentForm = appointmentForm;
  },
  RESET_GUEST_FIELDS(state) {
    if(state.appointmentForm) state.appointmentForm = state.appointmentForm.resetGuestFields();
  },
  RESET_APPOINTMENT_FORM(state) {
    state.appointmentForm = null;
  },
  RESET_MENUID(state) {
    state.menuId = null
  },
  SET_MENUID(state, menuId: MenuId) {
    state.menuId = menuId
  }
};

export const actions: ActionTree<AppointmentFormState, any> = {
  setMenuId({ commit },  menuId: MenuId) {
    commit("SET_MENUID", menuId);
  },
  setAppointmentForm({ commit }, appointmentForm: AppointmentForm) {
    commit("SET_APPOINTMENT_FORM", appointmentForm);
  },
  resetMenuId({ commit }) {
    commit("RESET_MENUID");
  },
  resetGuestFields({ commit }) {
    commit("RESET_GUEST_FIELDS");
  },
  resetAppointmentForm({ commit }) {
    commit("RESET_APPOINTMENT_FORM");
  },
};


