import { Appointment } from "@ambii-com/core";

export class AppointmentFetchError extends Error {
  appointment?: Appointment
  constructor(message: string, appointment?: Appointment) {
    super(message);
    this.appointment = appointment
    this.name = 'AppointmentFetchError';
  }
}
