
import Vue from "vue";
import { analytics } from "@/setups/firebase";
import { logEvent } from "firebase/analytics";
import { mapGetters } from "vuex";
import { LocaleWithLangName } from "~/classes/LocaleWithLangName";

export default Vue.extend({
  middleware: ["setAvailableLanguages"],
  data(): {
    drawer: Boolean;
  } {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
    ...mapGetters("appointment", ["getAvailableLanguages"]),
    displayLoginBtn(): boolean {
      return (
        !this.isLoggedIn &&
        !this.isOnLoginPage &&
        this.isOnPatientAppointmentPage
      );
    },
    displayAccountMenu(): boolean {
      return !!this.isLoggedIn && this.isOnPatientAppointmentPage;
    },
    localeName(): string {
      const selectedLang = this.getAvailableLanguages.find(
        (language: LocaleWithLangName) => language.locale === this.$i18n.locale
      );
      return selectedLang.name;
    },
    isOnLoginPage(): boolean {
      return (
        this.removeTrialingSlash(this.$nuxt.$route.path) ===
          this.localePath("/appointment/login") ||
        this.removeTrialingSlash(this.$nuxt.$route.path) ===
          this.localePath("/appointment/signup")
      );
    },
    isOnPatientAppointmentPage(): boolean {
      return this.removeTrialingSlash(this.$nuxt.$route.path).startsWith(
        this.localePath("/appointment")
      );
    },
    // isOnAdminAppointmentPage(): boolean {
    //   return this.removeTrialingSlash(this.$nuxt.$route.path).startsWith(this.localePath("/admin"))
    // },
    isOnFormPage(): boolean {
      return (
        this.removeTrialingSlash(this.$nuxt.$route.path).includes("/form") &&
        !this.removeTrialingSlash(this.$nuxt.$route.path).includes("/clinic") &&
        !this.removeTrialingSlash(this.$nuxt.$route.path).includes("/sent")
      );
    },
  },
  methods: {
    removeTrialingSlash(path: string) {
      return path.endsWith("/") ? path.slice(0, -1) : path;
    },
    drawerStart() {
      return (this.drawer = true);
    },
    drawerStop() {
      return (this.drawer = false);
    },
    setLang(locale: string) {
      logEvent(analytics, "locale_switch", {
        fromLocale: this.$i18n.locale,
        toLocale: locale,
      });
      this.$router.push(this.switchLocalePath(locale));
      this.$i18n.locale = locale;
    },
    async logout() {
      if (confirm(this.$t("app.login.confirmLogout"))) {
        await this.$store.dispatch("auth/logout");
        await this.$store.dispatch("appointmentForm/resetAppointmentForm");
        return this.$nuxt.$router.push(this.localePath("/appointment/login"));
      }
    },
  },
});
