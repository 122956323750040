
import { mapState } from "vuex";
import Header from "@/components/layout/Header.vue";
import OnProgress from "@/components/parts/OnProgress.vue";

export default {
  name: "default",
  components: { Header, OnProgress },
  data(): {
    networkError: Boolean;
    networkReconnected: Boolean;
  } {
    return {
      networkError: false,
      networkReconnected: false,
    };
  },
  computed: {
    ...mapState("site", ["progressMessage", "breadcrumbs"]),
  },
  created() {
    window.addEventListener("offline", () => {
      this.networkError = true;
    });
    window.addEventListener("online", () => {
      this.networkError = false;
      this.networkReconnected = true;
    });
  },
};
