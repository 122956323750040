// Add non schema related enums here
export const BugReportType = {
  UIISSUE: "UIIssue",
  CANNOTSUBMIT: "CannotSubmit",
  SLOWLOAD: "SlowLoad",
  OTHER: "OtherIssue",
};
export type BugReportType = typeof BugReportType[keyof typeof BugReportType];

export const SlackNotificationType = {
  CANCEL: "CANCEL",
  CREATE: "CREATE",
  RESCHEDULE: "RESCHEDULE",
}
export type SlackNotificationType = typeof SlackNotificationType[keyof typeof SlackNotificationType];
