export const state = () => ({
  breadcrumbs: [],
  progressMessage: null,
});

export const actions = {
  setBreadcrumbs({ commit }, breadcrumbs) {
    commit("SET_BREADCRUMBS", breadcrumbs);
  },
  showProgress({ commit }, progressMessage) {
    commit("SHOW_PROGRESS", progressMessage);
  },
  hideProgress({ commit }) {
    commit("HIDE_PROGRESS");
  },
};

export const mutations = {
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },
  SHOW_PROGRESS(state, progressMessage) {
    state.progressMessage = progressMessage;
  },
  HIDE_PROGRESS(state) {
    state.progressMessage = null;
  },
};
