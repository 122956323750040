import { fsLocations } from "~/dao/FsLocations";
import { UserRole } from "@ambii-com/core";

export default async function ({ app, store, redirect, route }) {
  if (!store.getters["auth/isLoggedIn"]) {
    redirect({ path: "/admin/login", query: { from: route.path } });
  } else {
    const isAdmin: boolean = await fsLocations
      .getAdminsRefByUserId(store.getters["auth/getUserId"])
      .get()
      .then((adminQuery) => {
        return adminQuery.empty
          ? false
          : adminQuery.docs[0].data().role === UserRole.CLINIC;
      });
    if (!isAdmin) {
      alert(app.i18n.t("middleware.pleaseUseAdminAccount"));
      await store.dispatch("auth/logout");
      redirect({
        path: app.localePath("/admin/login"),
        query: { from: route.path },
      });
    }
  }
}
