import { db, documentId } from "~/setups/firebase";
import { envVars, EnvVars } from "@ambii-com/core";
import { ClinicLifeCycleState } from "@ambii-com/core";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  FirebaseFirestore,
  Query,
} from "@firebase/firestore-types";

class FsLocations {
  clinicsRef: CollectionReference<DocumentData>;
  patientsRef: CollectionReference<DocumentData>;
  adminsRef: CollectionReference<DocumentData>;
  appointmentMenusRef: CollectionReference<DocumentData>;
  appointmentSchedulesRef: CollectionReference<DocumentData>;
  scheduleResourceSettingsRef: CollectionReference<DocumentData>;
  appointmentsRef: CollectionReference<DocumentData>;
  appointmentConfigsRef: CollectionReference<DocumentData>;
  formsRef: CollectionReference<DocumentData>;
  formConfigsRef: CollectionReference<DocumentData>;
  formSubmissionsRef: CollectionReference<DocumentData>;
  formPagesRef: CollectionReference<DocumentData>;
  formQuestionsRef: CollectionReference<DocumentData>;
  db: FirebaseFirestore;
  envVars: EnvVars;

  constructor(_db: FirebaseFirestore = db, _envVars: EnvVars = envVars) {
    this.db = _db;
    this.envVars = _envVars;
    this.clinicsRef = this.db.collection(this.envVars.clinicCollectionName);
    this.patientsRef = this.db.collection(this.envVars.patientCollectionName);
    this.adminsRef = this.db.collection(this.envVars.adminCollectionName);
    this.appointmentMenusRef = this.db.collection(
      this.envVars.appointmentMenusCollectionName
    );
    this.appointmentSchedulesRef = this.db.collection(
      this.envVars.appointmentSchedulesCollectionName
    );
    this.scheduleResourceSettingsRef = this.db.collection(
      this.envVars.scheduleResourceSettingCollectionName
    );
    this.appointmentsRef = this.db.collection(
      this.envVars.scheduleAppointmentCollectionName
    );
    this.appointmentConfigsRef = this.db.collection(this.envVars.appointmentConfigsCollectionName);
    this.formsRef = this.db.collection(this.envVars.formsCollectionName);
    this.formConfigsRef = this.db.collection(
      this.envVars.formsConfigCollectionName
    );
    this.formSubmissionsRef = this.db.collection(
      this.envVars.submissionsCollectionName
    );
    this.formPagesRef = this.db.collection(this.envVars.pagesCollectionName);
    this.formQuestionsRef = this.db.collection(
      this.envVars.questionsCollectionName
    );
    this.appointmentConfigsRef = this.db.collection(
      this.envVars.appointmentConfigsCollectionName
    );
  }

  getClinicRef(clinicId: ClinicId): DocumentReference<DocumentData> {
    return this.clinicsRef.doc(clinicId);
  }
  getClinicsRef(): CollectionReference<DocumentData> {
    return this.clinicsRef;
  }
  getClinicRefByUserId(userId: UserId): Query<DocumentData> {
    return this.clinicsRef.where("admin.uuid", "==", userId);
  }
  getMenuRef(menuId: MenuId): DocumentReference<DocumentData> {
    return this.appointmentMenusRef.doc(menuId);
  }
  getMenusRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.appointmentMenusRef.where("clinicId", "==", clinicId);
  }
  getMenusForPatientRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.getMenusRefByClinicId(clinicId).where("available", "==", true);
  }
  getSubmenusRefByMenuId(menuId: MenuId): Query<DocumentData> {
    return this.appointmentMenusRef.where("parentMenuId", "==", menuId);
  }
  getSubmenusForPatientRefByMenuId(menuId: MenuId): Query<DocumentData> {
    return this.getSubmenusRefByMenuId(menuId).where("available", "==", true);
  }
  getScheduleResourceSettingRef(
    submenuId: SubmenuId
  ): DocumentReference<DocumentData> {
    return this.scheduleResourceSettingsRef.doc(submenuId);
  }
  getSchedulesRefByMenuIdLegacy(
    menuId: MenuId
  ): Query<DocumentData> {
    return this.appointmentSchedulesRef.where("resourceId", "==", menuId);
  }
  getSchedulesRefByMenuId(
    menuId: MenuId
  ): Query<DocumentData> {
    return this.appointmentSchedulesRef.where("resourceId", "array-contains", menuId);
  }
  getOpenSchedulesRefBySubmenuId(
    submenuId: SubmenuId,
  ) {
    return this.appointmentSchedulesRef
      .where("resourceId", "==", submenuId)
  }
  getSchedulesRefBySubmenuIdAndDate(
    submenuId: SubmenuId,
    date: string
  ): Query<DocumentData> {
    return this.appointmentSchedulesRef
      .where("resourceId", "==", submenuId)
      .where("date", "==", date);
  }
  getScheduleRef(scheduleId: ScheduleId): DocumentReference<DocumentData> {
    return this.appointmentSchedulesRef.doc(scheduleId);
  }
  getAppointmentsRefByClinicIdAndMenuId(
    clinicId: ClinicId,
    menuId: MenuId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("menuId", "==", menuId)
      .where("clinicId", "==", clinicId);
  }
  getAppointmentsRefByBucketId(scheduleId: ScheduleId): Query<DocumentData> {
    return this.appointmentsRef.where("bucketId", "==", scheduleId);
  }
  // Note: in order for the fs rules for admins to work, querys must also contain clinicId
  getAppointmentsRefByClinicIdAndBucketId(
    clinicId: ClinicId,
    scheduleId: ScheduleId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("bucketId", "==", scheduleId)
      .where("clinicId", "==", clinicId);
  }
  getAppointmentsRefByClinicIdAndBucketIdAndDate(
    clinicId: ClinicId,
    scheduleId: ScheduleId,
    date: string
  ): Query<DocumentData> {
    return this.getAppointmentsRefByClinicIdAndBucketId(
      clinicId,
      scheduleId
    ).where("date", "==", date);
  }
  getAppointmentsRefByBucketIdAndUserId(
    scheduleId: ScheduleId,
    userId: UserId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("bucketId", "==", scheduleId)
      .where("userId", "==", userId);
  }
  getAppointmentsRefByUserId(userId: UserId): Query<DocumentData> {
    return this.appointmentsRef.where("userId", "==", userId);
  }
  getAppointmentsRefByResourceIdAndUserId(
    submenuId: SubmenuId,
    userId: UserId
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("resourceId", "==", submenuId)
      .where("userId", "==", userId);
  }
  getAppointmentsRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.appointmentsRef.where("clinicId", "==", clinicId);
  }
  getAppointmentsRefByClinicIdAndClinicLifeCycleState(
    clinicId: ClinicId,
    clinicLifeCycleState: ClinicLifeCycleState
  ): Query<DocumentData> {
    return this.getAppointmentsRefByClinicId(clinicId).where(
      "clinicLifeCycleState",
      "==",
      clinicLifeCycleState
    );
  }
  getAppointmentsRefByClinicIdAndDate(
    clinicId: ClinicId,
    date: string
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("clinicId", "==", clinicId)
      .where("date", "==", date);
  }
  getAppointmentsRefByClinicIdAndMenuIdAndDate(
    clinicId: ClinicId,
    menuId: string,
    date: string
  ): Query<DocumentData> {
    return this.appointmentsRef
      .where("clinicId", "==", clinicId)
      .where("menuId", "==", menuId)
      .where("date", "==", date);
  }
  getAppointmentsRefByClinicIdAndDateAndClinicLifeCycleState(
    clinicId: ClinicId,
    date: string,
    clinicLifeCycleState: ClinicLifeCycleState
  ): Query<DocumentData> {
    return this.getAppointmentsRefByClinicIdAndDate(clinicId, date).where(
      "clinicLifeCycleState",
      "==",
      clinicLifeCycleState
    );
  }
  getAppointmentsRefByClinicIdAndMenuIdAndDateAndClinicLifeCycleState(
    clinicId: ClinicId,
    menuId: string,
    date: string,
    clinicLifeCycleState: ClinicLifeCycleState
  ): Query<DocumentData> {
    return this.getAppointmentsRefByClinicIdAndMenuIdAndDate(
      clinicId,
      menuId,
      date
    ).where("clinicLifeCycleState", "==", clinicLifeCycleState);
  }
  getAppointmentsRefByClinicIdAndMenuIdAndClinicLifeCycleState(
    clinicId: ClinicId,
    menuId: string,
    clinicLifeCycleState: ClinicLifeCycleState
  ): Query<DocumentData> {
    return this.getAppointmentsRefByClinicIdAndMenuId(clinicId, menuId).where(
      "clinicLifeCycleState",
      "==",
      clinicLifeCycleState
    );
  }
  getAppointmentRef(
    appointmentId: AppointmentId
  ): DocumentReference<DocumentData> {
    return this.appointmentsRef.doc(appointmentId);
  }
  getPatientsRef(userId: UserId): DocumentReference<DocumentData> {
    return this.patientsRef.doc(userId);
  }
  getAdminsRef(userId: UserId): DocumentReference<DocumentData> {
    return this.adminsRef.doc(userId);
  }
  getPatientsRefByUserId(userId: UserId) {
    return this.patientsRef.where("uid", "==", userId).limit(1);
  }
  getAdminsRefByUserId(userId: UserId) {
    return this.adminsRef.where("uid", "==", userId).limit(1);
  }
  // Form pages fsLocations
  getFormsRefByClinicId(clinicId: ClinicId): CollectionReference<DocumentData> {
    return this.getClinicRef(clinicId).collection(
      this.envVars.formsCollectionName
    );
  }
  getFormRefByAppointmentId(
    clinicId: ClinicId,
    appointmentId: string
  ): Query<DocumentData> {
    return this.getFormsRefByClinicId(clinicId)
      .where("appointment.appointmentId", "==", appointmentId)
      .limit(1);
  }
  getFormRef(formId: FormId): DocumentReference<DocumentData> {
    return this.formsRef.doc(formId);
  }
  getFormRefsByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.formsRef.where("clinicId", "==", clinicId);
  }
  getFormRefByAvailabilityAndLocale(
    clinicId: ClinicId,
    available: boolean,
    locale: string
  ): Query<DocumentData> {
    return this.getFormRefsByClinicId(clinicId)
      .where("available", "==", available)
      .where("locale", "==", locale);
  }
  getFormConfigRef(configId: string): DocumentReference<DocumentData> {
    return this.formConfigsRef.doc(configId);
  }
  getFormConfigRefByShortLinkPath(shortLinkPath: string): Query<DocumentData> {
    return this.formConfigsRef.where("shortLinkPath", "==", shortLinkPath).limit(1);
  }
  getFormSubmissionRef(
    SubmissionId: SubmissionId
  ): DocumentReference<DocumentData> {
    return this.formSubmissionsRef.doc(SubmissionId);
  }
  // getFormSubmissionRefByAppointmentId(
  //   appointmentId: AppointmentId
  // ): Query<DocumentData> {
  //   return this.formSubmissionsRef.where("appointmentId", "==", appointmentId).limit(1);;
  // }
  getFormQuestion(questionId: QuestionId): DocumentReference<DocumentData> {
    return this.formQuestionsRef.doc(questionId);
  }
  getFormPageRef(formPageId: FormPageId): DocumentReference<DocumentData> {
    return this.formPagesRef.doc(formPageId);
  }
  getFormPageRefsFromFormPageIds(
    formPageIds: Array<FormPageId>
  ): Array<Query<DocumentData>> {
    const chunkedFormPageIds = FsLocations.chunkArray(formPageIds, 10);
    const querys: Query<DocumentData>[] = chunkedFormPageIds.map(
      (FormPageIds) => this.formPagesRef.where(documentId, "in", FormPageIds)
    );
    return querys;
  }
  getClinicsRefByActiveService(service: string): Query<DocumentData> {
    return this.getClinicsRef().where(
      "activeServices",
      "array-contains",
      service
    );
  }
  getAppointmentConfigRef(appointmentConfigId: string) {
    return this.appointmentConfigsRef.doc(appointmentConfigId);
  }
  getAppointmentConfigRefByClinicId(clinicId: ClinicId): Query<DocumentData> {
    return this.appointmentConfigsRef.where("clinicId", "==", clinicId);
  }
  static chunkArray(list: string[], chunk: number): string[][] {
    const result = [];
    for (let i = 0; i < list.length; i += chunk) {
      result.push(list.slice(i, i + chunk));
    }
    return result;
  }
}
const fsLocations = new FsLocations();

export { FsLocations, fsLocations };
