import { Context } from "@nuxt/types";
import { fsLocations } from "~/dao/FsLocations";
import {
  Menu,
  AppointmentSchedule,
  Appointment,
  AppointmentConfig,
  Form
} from "@ambii-com/core";
import { allLanguages } from "~/utils/i18n";
import { LocaleWithLangName } from "~/classes/LocaleWithLangName";

// for /appointment clinic specific pages pages gets appointmentConfig and sets available languages in header
// defaults to all languages supported in app
export default async function({ store, route }: Context) {
  if (!store.getters["auth/clinicId"] && route.path.includes("/appointment/")) {
    let clinicId: string;
    if (!!route.params.clinicId) {
      clinicId = route.params.clinicId;
    } else if (!!route.params.menuId) {
      clinicId = (await fsLocations
        .getMenuRef(route.params.menuId)
        .get()
        .then(Menu.fromDoc)).clinicId;
    } else if (!!route.params.scheduleId) {
      clinicId = (await fsLocations
        .getScheduleRef(route.params.scheduleId)
        .get()
        .then(AppointmentSchedule.fromDoc)).clinicId;
    } else if (!!route.params.appointmentId) {
      clinicId = (await fsLocations
        .getAppointmentRef(route.params.appointmentId)
        .get()
        .then(Appointment.fromDoc)).clinicId;
    }
    if (clinicId) {
      const appointmentConfig = await fsLocations
        .getAppointmentConfigRefByClinicId(clinicId)
        .limit(1)
        .get()
        .then(({ docs }) => AppointmentConfig.fromDocs(docs));
      let availableLanguages: LocaleWithLangName[];
      if (!!appointmentConfig.length) {
        // filtering allLanguages out languages not in availableLanguage in appointmentConfig
        availableLanguages = allLanguages.filter(language =>
          appointmentConfig[0].availableLanguages.includes(language.locale)
        );
        store.dispatch("appointment/setAvailableLanguages", availableLanguages);
      }
    }
    // for /form clinic specific pages, gets available forms locale and sets available languages in header
  } else if (!store.getters["auth/clinicId"] && route.path.includes("/form/")) {
    let clinicId: string;
    if (!!route.params.clinicId) {
      clinicId = route.params.clinicId;
    } else if (!!route.params.formId) {
      clinicId = (await fsLocations
        .getFormRef(route.params.formId)
        .get()
        .then(Form.fromDoc)).clinicId;
    }
    if (clinicId) {
      const clinicForms = await fsLocations
        .getFormRefsByClinicId(clinicId)
        .get()
        .then(({ docs }) => Form.fromDocs(docs));

      const availableForms = clinicForms.filter(form => form.available); // filter out forms that are not available
      const formLocales = new Set(availableForms.map(form => form.locale)); // set new object with locales of forms

      let availableLanguages: LocaleWithLangName[];
      if (!!clinicForms.length) {
        // filtering allLanguages with languages in formLocales
        availableLanguages = allLanguages.filter(language =>
          formLocales.has(language.locale)
        );
        store.dispatch("appointment/setAvailableLanguages", availableLanguages);
      }
    }
  }
}
