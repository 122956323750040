import { Context } from "@nuxt/types";

export default function ({ route, store }: Context) {
  const pathSplitted = route.fullPath.split("/");
  if (pathSplitted.length < 2 || pathSplitted[1] !== "admin") {
    store.dispatch("site/setBreadcrumbs", []);
  } else {
    store.dispatch("site/setBreadcrumbs", [{ text: "..." }, { text: "..." }]);
  }
}
