import { LocaleWithLangName } from "~/classes/LocaleWithLangName";
import { allLanguages } from "~/utils/i18n"

export const state = () => ({
  availableLanguages: allLanguages as LocaleWithLangName[],
});

export const getters = {
  getAvailableLanguages: (state: any) => state.availableLanguages,
};

export const actions = {
  setAvailableLanguages({ commit }, availableLanguages: LocaleWithLangName[]) {
    commit("SET_AVAILABLE_LANGUAGES", availableLanguages);
  },
};

export const mutations = {
  SET_AVAILABLE_LANGUAGES(
    state: any,
    availableLanguages: LocaleWithLangName[]
  ) {
    state.availableLanguages = availableLanguages;
  },
};
