
import { NuxtError } from "@nuxt/types";
import Vue, { PropOptions } from "vue";

abstract class AmbiiErrorInterface {
  statusCode: string;
  messageI18nKey: string;
  titleI18nKey: string;
  toFormHome: boolean;
  toAppointmentHome: boolean;
}

class Ambii404Error extends AmbiiErrorInterface {
  toFormHome: boolean = true;
  toAppointmentHome: boolean = false;
  statusCode = "404";
  messageI18nKey: string = "error.message.404";
  titleI18nKey: string = "error.title.404";
}

class Ambii408Error extends AmbiiErrorInterface {
  toFormHome: boolean = true;
  toAppointmentHome: boolean = false;
  statusCode = "408";
  messageI18nKey: string = "error.message.408";
  titleI18nKey: string = "error.title.408";
}

class Ambii500Error extends AmbiiErrorInterface {
  toFormHome: boolean = true;
  toAppointmentHome: boolean = false;
  statusCode = "500";
  messageI18nKey: string = "error.message.500";
  titleI18nKey: string = "error.title.500";
}

class AmbiiCustomError extends AmbiiErrorInterface {
  constructor(
    statusCode: string = "500",
    messageI18nKey: string = "error.message.500",
    titleI18nKey: string = "error.title.500",
    toFormHome: boolean = true,
    toAppointmentHome: boolean = false
  ) {
    super();
    this.statusCode = statusCode;
    this.messageI18nKey = messageI18nKey;
    this.titleI18nKey = titleI18nKey;
    this.toFormHome = toFormHome;
    this.toAppointmentHome = toAppointmentHome;
  }
}

export default Vue.extend({
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: {},
    } as PropOptions<NuxtError>,
  },
  computed: {
    ambiiError(): AmbiiErrorInterface {
      if (!!this.error.custom) {
        return new AmbiiCustomError(
          this.error.statusCode,
          this.error.messageI18nKey,
          this.error.titleI18nKey,
          this.error.toFormHome,
          this.error.toAppointmentHome
        );
      } else {
        switch (this.error.statusCode) {
          case 404:
            return new Ambii404Error();
          case 408:
            return new Ambii408Error();
          case 500:
            return new Ambii500Error();
          default:
            return new Ambii500Error();
        }
      }
    },
    errorCode(): string {
      return this.ambiiError.statusCode;
    },
    title(): string {
      return this.$t(this.ambiiError.titleI18nKey);
    },
    message() {
      return this.$t(this.ambiiError.messageI18nKey);
    },
    // toFormHome(): boolean {
    //   return this.ambiiError.toFormHome;
    // },
    // toAppointmentHome(): boolean {
    //   return this.ambiiError.toAppointmentHome;
    // },
  },
  head() {
    return {
      title: `${this.error.statusCode} | ${this.title}`,
    };
  },
});
