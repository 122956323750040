import { auth } from "~/setups/firebase";
import { signOut } from "firebase/auth";

export const state = () => ({
  userId: null,
  email: null,
  // userRole: null,
  clinicId: null,
  clinic: null,
});

export const getters = {
  getUserId: (state) => state.userId,
  getEmail: (state) => state.email,
  isLoggedIn: (state) => !!state.userId,
  getClinic: (state) => state.clinic,
};

export const actions = {
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  // setUserRole({ commit }, role) {
  //     commit("SET_USER_ROLE", role)
  // },
  logout({ commit }) {
    signOut(auth).then(() => {
      commit("LOGOUT");
    });
  },
  setClinicId({ commit }, clinicId) {
    commit("SET_CLINICID", clinicId);
  },
  setClinic({ commit }, clinic) {
    commit("SET_CLINIC", clinic);
  }
};

export const mutations = {
  SET_USER(state, user) {
    state.userId = user.uid;
    state.email = user.email
  },
  // SET_USER_ROLE(state, role) {
  //     state.userRole = role
  // },
  LOGOUT(state) {
    state.clinicId = state.userId = null;
  },
  SET_CLINICID(state, clinicId) {
    state.clinicId = clinicId;
  },
  SET_CLINIC(state, clinic) {
    state.clinic = clinic;
  }
};
