import joinPath from "path.join";
import { noEmptyRules } from "~/utils/formValidation";

export default (context, inject) => {
  inject("join", joinPath);
  inject("parentPath", (path: string) =>
    path.split("/").slice(0, -1).join("/")
  );
  inject("noEmptyRules", noEmptyRules);
};
